import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ErrorBoundary } from "react-error-boundary"
import { selectCartItemsTotalQty } from '../../features/cartItems/cartItemsSlice'
import { useGetUserQuery, useGetSettingsBySlugsQuery, useGetUserMcrmQuery } from '../../features/backend/backendApiSlice'
import { SLUG_SETTING_RESTRICTED_DATES } from '../../utils/enums'
import { Link } from "react-router-dom"
import MenuLink from "../MenuLink"
import Menu from "./Menu"
import Footer from "./Footer"
import FormFieldSearch from "../Form/FormFieldSearch"
import BannerCookies from '../BannerCookies'
import BannerTopline from '../BannerTopline'
import SearchForm from '../SearchForm'
import ErrorFallback from '../ErrorFallback'
import { logError } from '../../utils/functions'

const Layout = ({ children }) => {
  const { data: user } = useGetUserQuery()
  const { data: settings } = useGetSettingsBySlugsQuery([SLUG_SETTING_RESTRICTED_DATES])

  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const cartItemsTotalQty = useSelector(selectCartItemsTotalQty)

  const { pathname, search } = useLocation()
  console.log(pathname)

  useEffect(() => {
    setIsMobileMenuOpen(false)

    // eslint-disable-next-line
    ym(45440976, "hit", pathname);
  }, [pathname, search])
  
  console.log(user)

  useEffect(() => {
    // UTM
    let query = window.location.search.substring(1)
    let getParams = query.split('&')
    let utms = []
    for (let i = 0; i < getParams.length; i++) {
      let pair = getParams[i].split('=')
      if (pair[0].indexOf('utm_') == 0) {
        utms.push({ tag: pair[0], value: pair[1] })
      }
    }
    if (utms.length > 0) {
      localStorage.setItem('utms', JSON.stringify(utms))
    }
  }, [])

  const { refetch: refetchUserMcrm } = useGetUserMcrmQuery()

  const onFocusGlobal = () => {
    refetchUserMcrm()
  }

  useEffect(() => {
      window.addEventListener("focus", onFocusGlobal)
      return () => {
        window.removeEventListener("focus", onFocusGlobal)
      }
    }, [])

  return (
    <>
      <div className="header">
        <div><BannerTopline /></div>
        <div className="container">
          <div className="menu-container">
            <SearchForm isSearchOpen={isSearchOpen} setIsSearchOpen={setIsSearchOpen} />
            <div className={`menu-burger hide-lg hide-xl hide-xxl ${isMobileMenuOpen ? 'active' : ''}`} onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
              {isMobileMenuOpen ? <img src="/images/icons/close.png" alt="" /> : <img src="/images/icons/menu-burger.svg" alt="" />}
              <div>{isMobileMenuOpen ? 'Закрыть' : 'Меню'}</div>
            </div>
            <div className="menu menu-logo">
              <Link to="/" onClick={() => setIsMobileMenuOpen(false)}><img className="logo" src="/images/logo.png" alt="" /></Link>
              <div className="menu-links hide-xs hide-sm hide-md">
                <Menu />
              </div>
            </div>
            <div className="menu">
              <div className="menu-links-icons">
                <div className="menu-link hide-xs hide-sm" onClick={() => setIsSearchOpen(!isSearchOpen)}><div className="menu-links-icon"><img src="/images/icons/search.svg" alt="" /></div></div>
                <MenuLink to="tel:8 800 777-04-14" label={<div className="menu-links-icon"><img src="/images/icons/phone.svg" alt="" /></div>} />
                <MenuLink to="/me" label={<div className="menu-links-icon">
                  {!user?.id
                    ? <img src="/images/icons/user.svg" alt="" />
                    : (user.customer.avatar ? <img className="menu-avatar" src={import.meta.env.VITE_BACKEND_URL + user.customer.avatar} alt="" /> : <img src="/images/icons/user-active.svg" alt="" />)
                  }
                </div>} />
                <MenuLink to="/cart" label={<div className="menu-links-icon">{cartItemsTotalQty > 0 && <div className="menu-links-icon__badge">{cartItemsTotalQty}</div>}<img src="/images/icons/cart.svg" alt="" /></div>} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-spacer"></div>
      <div className={`menu-mobile ${isMobileMenuOpen ? 'display-flex' : ''}`}>
        <Menu mobile={true} />
      </div>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={(details) => {
          // reset the state of your app so the error doesn't happen again
          console.log(details)
        }}
        onError={logError}
      >
        {children}
      </ErrorBoundary>
      <BannerCookies />
      <Footer />
    </>
  )
}

export default Layout