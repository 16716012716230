import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { logout } from '../utils/api'
import { useGetUserQuery, useGetUserMcrmQuery } from '../features/backend/backendApiSlice'
import ProfileData from '../components/Profile/ProfileData'
import ProfileAddresses from '../components/Profile/ProfileAddresses'
import ProfileOrders from '../components/Profile/ProfileOrders'
import ProfileFavorites from '../components/Profile/ProfileFavorites'
import ProfileBonuses from '../components/Profile/ProfileBonuses'
import ProfileReviews from '../components/Profile/ProfileReviews'
import useTitle from "../hooks/useTitle"
import useCanonical from "../hooks/useCanonical"

const ProfileView = () => {
  useTitle('Личный кабинет')
  useCanonical(import.meta.env.VITE_FRONTEND_URL + 'me')

  const navigate = useNavigate()
  const { data: user, isError, isLoading, isSuccess, refetch, isFetching } = useGetUserQuery()
  const { data: userMcrm, refetch: refetchMcrm } = useGetUserMcrmQuery()

  // if userMcrm.error is not empty - refetch it every 1 second until it's empty, but limited to 2 minutes
  useEffect(() => {
    let interval
    if (userMcrm?.error) {
      let attempts = 0
      interval = setInterval(() => {
        if (attempts >= 60) {
          clearInterval(interval)
          return
        }
        attempts++
        refetchMcrm()
      }, 2000)
    }
    return () => clearInterval(interval)
  }, [userMcrm])

  // const urlParams = new URLSearchParams(window.location.search)
  // const tabParam = urlParams.get('tab')
  let tabHash = window.location.hash.replace('#', '')
  const options = ['main', 'addresses', 'history', 'favorites', 'bonuses', 'reviews']

  console.log(user)
  
  useEffect(() => {
    if (!isLoading && !user?.id) {
      navigate('/login')
    }
  }, [isLoading, user])

  const [activeTab, setActiveTab] = useState(options.includes(tabHash) ? tabHash : 'main')

  const [isLoggingOut, setIsLoggingOut] = useState(false)

  const handleLogout = async () => {
    setIsLoggingOut(true)
    await logout()
    localStorage.removeItem('token')
    await refetch()
    setIsLoggingOut(false)
    navigate('/login')
  }

  const handleChangeTab = (tab) => {
    // add a #tab to the url
    window.location.hash = tab
    setActiveTab(tab)
  }

  return (
    <div className="container">
      <div className="heading-h1 heading-h1-profile">Личный кабинет</div>
      {(!isLoading && user?.id) && <div className="tabs-container">
        <div className="tabs tabs-profile">
          <div className={`tab-link ${activeTab == 'main' ? 'active' : ''}`} onClick={() => handleChangeTab('main')}>Личные данные</div>
          <div className={`tab-link ${activeTab == 'addresses' ? 'active' : ''}`} onClick={() => handleChangeTab('addresses')}>Адреса</div>
          <div className={`tab-link ${activeTab == 'history' ? 'active' : ''}`} onClick={() => handleChangeTab('history')}>История заказов</div>
          <div className={`tab-link ${activeTab == 'favorites' ? 'active' : ''}`} onClick={() => handleChangeTab('favorites')}>Избранные товары</div>
          <div className={`tab-link ${activeTab == 'bonuses' ? 'active' : ''}`} onClick={() => handleChangeTab('bonuses')}>Бонусная система</div>
          <div className={`tab-link ${activeTab == 'reviews' ? 'active' : ''}`} onClick={() => handleChangeTab('reviews')}>Отзывы</div>
          <div className="profile-logout" onClick={() => handleLogout()}>{isLoggingOut === false ? 'Выйти из аккаунта' : 'Выходим...'}</div>
        </div>
        <div className="tabs-content">
          <ProfileData active={activeTab === 'main'} />
          <ProfileAddresses active={activeTab === 'addresses'} />
          <ProfileOrders active={activeTab === 'history'} />
          <ProfileFavorites active={activeTab === 'favorites'} />
          <ProfileBonuses active={activeTab === 'bonuses'} />
          <ProfileReviews active={activeTab === 'reviews'} />
        </div>
      </div>}
    </div>
  )
}

export default ProfileView